import React, {Fragment, Suspense, lazy} from "react";
import {Route, Switch} from "react-router-dom";

import Loading from "./components/loading/Loading";

const Home = lazy(() => import("./pages/Home"));
// const Blog = lazy(() => import("./pages/Blog"));
const NotFound = lazy(() => import("./pages/NotFound"));

// const Privacy = lazy(() => import("../../pages/privacy/Privacy"));
// const Terms = lazy(() => import("../../pages/terms/Terms"));
// const AboutUs = lazy(() => import("../../pages/about/AboutUs"));
// const AboutTest = lazy(() => import("../../pages/about/AboutTest"));
// const Blog = lazy(() => import("../../pages/blog/Blog"));

const Routes = ({isAuthenticated}) => {
	//console.log(isAuthenticated);

	return (
		<Fragment>
			<Suspense
				fallback={
					<div
						style={{
							textAlign: "center",
							marginTop: "20%",
						}}
					>
						<Loading></Loading>
					</div>
				}
			>
				<Switch onUpdate={() => window.scrollTo(0, 0)}>
					<Route exact path="/" component={Home} />
					{/* <Route exact path="/blog" component={Blog} /> */}

					{/* <Route exact path="/college-admission" component={CollegeAdmission} />
					<Route
						exact
						path="/sat-master-class-pro"
						component={SatMasterClassPro}
					/>
					<Route exact path="/ib-tutoring" component={IBTutoring} />
					
					
					<Route exact path="/about" component={AboutUs} />
					<Route exact path="/about-test" component={AboutTest} />
					<Route exacy path="/blog" component={Blog} />
					<Route exact path="/privacy" component={Privacy} />
					<Route exact path="/terms" component={Terms} /> */}

					<Route path="*" component={NotFound} />
				</Switch>
			</Suspense>
		</Fragment>
	);
};

export default Routes;
