import React, {Fragment} from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import Routes from "./Routes";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
	return (
		<Router>
			<Fragment>
				<Route component={Routes} />
			</Fragment>
		</Router>
	);
}

export default App;
