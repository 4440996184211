import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
export default class App extends React.Component {
	//other logic
	render() {
		return (
			<Loader
				type="Rings"
				color="#1F0744"
				height={200}
				width={200}
				timeout={5000} //3 secs
			/>
		);
	}
}
